import { useMainStore } from "~/store";

export default defineNuxtPlugin((nuxtApp) => {
  if (!import.meta.browser) return false;
  const staticArr = ["contact-us", "work-with-us", "about-us"];
  const arr = ["country-products-slug"];
  let enabled = false;
  const router = useRouter();
  const store = useMainStore();
  if (store && store.pageLinks) {
    const pageName = nuxtApp.$getKeyByValue(
      store.pageLinks,
      router.currentRoute.value.params.page
    );

    if (
      (staticArr.includes(pageName) ||
        arr.includes(router.currentRoute.value.name)) &&
      !enabled
    ) {
      import("aos").then((module) => {
        import("aos/dist/aos.css").then((c) => {
          enabled = true;
          // Initialize AOS
          nuxtApp.AOS = new module.init(); // eslint-disable-line new-cap
        });
      });
    }
    nuxtApp.hook("page:start", () => {
      const dynamicName = nuxtApp.$getKeyByValue(
        store.pageLinks,
        router.currentRoute.value.params.page
      );
      if (
        (staticArr.includes(dynamicName) ||
          arr.includes(router.currentRoute.value.name)) &&
        !enabled
      ) {
        import("aos").then((module) => {
          import("aos/dist/aos.css").then((c) => {
            enabled = true;
            // Initialize AOS
            nuxtApp.AOS = new module.init(); // eslint-disable-line new-cap
          });
        });
      }
    });
  }
});
