import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/usr/src/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_dw2T9lEw4h from "/usr/src/app/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_izaWKZ8rEu from "/usr/src/app/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/usr/src/app/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_PSbK9A48ZQ from "/usr/src/app/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import cookies_fWsGjKD4Pq from "/usr/src/app/plugins/cookies.js";
import dynamicFont_NhW8lNKU0g from "/usr/src/app/plugins/dynamicFont.js";
import facebook_pixels_client_iFxG9yYran from "/usr/src/app/plugins/facebook-pixels.client.js";
import fetch_Epa1FcTLYd from "/usr/src/app/plugins/fetch.js";
import gtag_client_R70iZRv6jK from "/usr/src/app/plugins/gtag.client.js";
import gtm_client_KEcT5dOoKs from "/usr/src/app/plugins/gtm.client.js";
import helpers_ipnH1tCNhR from "/usr/src/app/plugins/helpers.js";
import i18n_sVHQBgnb3t from "/usr/src/app/plugins/i18n.js";
import init_baidu_map_client_SSfJR08GA3 from "/usr/src/app/plugins/init-baidu-map.client.js";
import init_google_map_client_7fANxzdu0u from "/usr/src/app/plugins/init-google-map.client.js";
import intercom_client_sjSaVpgP3i from "/usr/src/app/plugins/intercom.client.js";
import iubenda_nlxkQdKJ3K from "/usr/src/app/plugins/iubenda.js";
import metricool_pixel_client_cf7BRnIgRI from "/usr/src/app/plugins/metricool-pixel.client.js";
import microsoft_clarity_client_6mcs7eGuBF from "/usr/src/app/plugins/microsoft-clarity.client.js";
import pusher_8SBvNoBVEM from "/usr/src/app/plugins/pusher.js";
import route_kswtQZzvuQ from "/usr/src/app/plugins/route.js";
import seo_A7Wq25CVUt from "/usr/src/app/plugins/seo.js";
import seometa_5PmiWpSr2B from "/usr/src/app/plugins/seometa.js";
import vue_anime_xyz_client_UB44ZVEynW from "/usr/src/app/plugins/vue-anime-xyz.client.js";
import vue_aos_client_nlOTbQH58f from "/usr/src/app/plugins/vue-aos.client.js";
import vue_click_away_9t9kKSkZ96 from "/usr/src/app/plugins/vue-click-away.js";
import vue_credit_card_validation_client_2AZ3WPWA6P from "/usr/src/app/plugins/vue-credit-card-validation.client.js";
import vue_final_modal_client_YWrwgOFW2h from "/usr/src/app/plugins/vue-final-modal.client.js";
import vue_select_Hligc0DBp2 from "/usr/src/app/plugins/vue-select.js";
import vue_slider_component_client_hM1WCVBbRD from "/usr/src/app/plugins/vue-slider-component.client.js";
import vue_toastify_client_vJl9WY8SCz from "/usr/src/app/plugins/vue-toastify.client.js";
import defaults_aRl2YLnTcb from "/usr/src/app/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_dw2T9lEw4h,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  plugin_PSbK9A48ZQ,
  cookies_fWsGjKD4Pq,
  dynamicFont_NhW8lNKU0g,
  facebook_pixels_client_iFxG9yYran,
  fetch_Epa1FcTLYd,
  gtag_client_R70iZRv6jK,
  gtm_client_KEcT5dOoKs,
  helpers_ipnH1tCNhR,
  i18n_sVHQBgnb3t,
  init_baidu_map_client_SSfJR08GA3,
  init_google_map_client_7fANxzdu0u,
  intercom_client_sjSaVpgP3i,
  iubenda_nlxkQdKJ3K,
  metricool_pixel_client_cf7BRnIgRI,
  microsoft_clarity_client_6mcs7eGuBF,
  pusher_8SBvNoBVEM,
  route_kswtQZzvuQ,
  seo_A7Wq25CVUt,
  seometa_5PmiWpSr2B,
  vue_anime_xyz_client_UB44ZVEynW,
  vue_aos_client_nlOTbQH58f,
  vue_click_away_9t9kKSkZ96,
  vue_credit_card_validation_client_2AZ3WPWA6P,
  vue_final_modal_client_YWrwgOFW2h,
  vue_select_Hligc0DBp2,
  vue_slider_component_client_hM1WCVBbRD,
  vue_toastify_client_vJl9WY8SCz,
  defaults_aRl2YLnTcb
]