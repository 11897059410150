export default defineNuxtPlugin((nuxtApp) => {
  const route = useRoute();
  const $config = nuxtApp.$config.public;
  const seoModuleFunc = async (props) => {
    props = {
      url: $config.APP_URL + route.fullPath,
      meta_description: null,
      og_image: null,
      og_title: props.og_name,
      meta_name: props.meta_name + ' | ' + $config.APP_NAME,
      ...props,
      page_title: props.page_title ? props.page_title + ' | ' + $config.APP_NAME : $config.APP_NAME,
    }


    if (props.model_type != 'Product' && props.model_type != 'Post') {
      try {
        const { data, error } = await useAPI(`/api/front/seo/${props.model_id}/${props.model_type}`)

        if (!error.value && data.value) {
          props = {
            ...props,
            ...data.value.data
          }
        }

      } catch (e) {
        console.log(e)
      }
    }
    const attributes = {}

    if (props.og_image) {
      attributes.value = {
        ...attributes.value,
        ogImage: props.og_image,
      };
    }

    if (!props.og_image) {
      defineOgImageComponent("OgImageDynamicPage", {
        name: props.name,
        title: props.page_title,
        url: props.url,
        description: props.og_description,
        theme: "#5accf0",
        colorMode: "light",
      }, {
        alt: props.og_image_alt,
      });
    }

    useSeoMeta({
      name: props.meta_name,
      description: props.meta_description,
      keywords: props.meta_keywords,
      title: props.page_title,
      ogTitle: props.og_title,
      ogDescription: props.og_description,
      ogImageAlt: props.og_image_alt,
      ...attributes.value,
    });
  }

  nuxtApp.provide('seoModule', seoModuleFunc);
})
